import React from 'react';

const Spinner = () => (
    <div className="container-loading">
        <div className="spinner-border text-primary" role="status">
            <span className="sr-only"></span>
        </div>
        <span style={{ marginTop: '30px' }}>Por favor, aguarde...</span>
    </div>
)

export default Spinner;