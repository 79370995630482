import {
  SET_LISTA_CARTOES,
  SET_CONSULTANDO_CARTOES
} from '../actions/actionsTypes';

export const creditCards = (state = { cards: [], isLoading: false}, action) => {
  switch (action.type) {
    case SET_CONSULTANDO_CARTOES:
      return {
        ...state,
        isLoading: true
      }
    case SET_LISTA_CARTOES:
      return {
        ...state,
        cards: action.payload.cards,
        isLoading: action.payload.isLoading
      }
    default:
      return state;
  }
}