import React from 'react';
import './App.css';
import { BrowserRouter as Router, Route } from "react-router-dom";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Pay, CreditCard, PayOnline, CreditCardOnline } from './components';
import { Provider } from 'react-redux';
import { Store } from './Store';

toast.configure({
  autoClose: 5000
});

const App = () => (
  <Provider store={Store}>
    <Router>
      <Route exact path="/card" component={CreditCard} />
      <Route exact path="/pay" component={Pay} />
      <Route exact path="/payonline" component={PayOnline} />
      <Route exact path="/cardonline" component={CreditCardOnline} />
    </Router>
  </Provider>
)
export default App;
