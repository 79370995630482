import React, { Component } from 'react';
import Cards from 'react-credit-cards';
import { Row, FormGroup, Input, Col, Label } from 'reactstrap';
import { formatExpirationDate, formatCVC, formatCreditCardNumber, formatCpfCnpj, formatPhone, formatPostalCode, formatData } from './utils';
import axios from 'axios';
import { toast } from 'react-toastify';
import Spinner from './Spinner';
import moment from 'moment';

export default class CreditCard extends Component {

  state = {
    nameCard: '',
    numberCard: '',
    expiry: '',
    cvc: '',
    focused: '',
    brand: '',
    name: '',
    document: '',
    bornDate: '',
    phone: '',
    postalCode: '',
    street: '',
    number: '',
    complement: '',
    district: '',
    city: '',
    state: '',
    latitude: 0,
    longitude: 0,
    isSaving: true
  }

  componentDidMount() {
    let queryParams = new URLSearchParams(this.props.location.search);
    let token = queryParams.get('token');
    let app = queryParams.get('app');
    let sessionId = queryParams.get('sessionId');

    if (!token || !app) {
      toast.error('Não foi possível autorizar!', { position: toast.POSITION.TOP_RIGHT });
      window.location.href = `${window.location.href.split('&typeReturn')[0]}&typeReturn=error&message=${encodeURIComponent('Não foi possível autorizar!')}`;
      return;
    }

    if (!sessionId) {
      toast.error('Sessão não informada!', { position: toast.POSITION.TOP_RIGHT });
      window.location.href = `${window.location.href.split('&typeReturn')[0]}&typeReturn=error&message=${encodeURIComponent('Sessão não informada!')}`;
      return;
    }

    const name = queryParams.get('name') || '';
    const document = queryParams.get('document') || '';
    const bornDate = queryParams.get('bornDate') || '';
    const phone = queryParams.get('phone') || '';
    const postalCode = queryParams.get('postalCode') || '';
    const street = queryParams.get('street') || '';
    const number = queryParams.get('number') || '';
    const complement = queryParams.get('complement') || '';
    const district = queryParams.get('district') || '';
    const city = queryParams.get('city') || '';
    const state = queryParams.get('state') || '';
    const latitude = queryParams.get('latitude') || 0;
    const longitude = queryParams.get('longitude') || 0;

    this.setState({
      isSaving: false,
      name: name,
      document: formatCpfCnpj(document),
      bornDate: bornDate ? moment(bornDate).format('DD/MM/YYYY') : '',
      phone: formatPhone(phone),
      postalCode: formatPostalCode(postalCode),
      street,
      number,
      complement,
      district,
      city,
      state,
      latitude,
      longitude
    })
  }

  handleInputFocus = ({ target }) => {
    this.setState({
      focused: target.name,
    });
  };

  handleInputChange = ({ target }) => {
    if (target.name === 'numberCard') {
      target.value = formatCreditCardNumber(target.value);
    } else if (target.name === 'expiry') {
      target.value = formatExpirationDate(target.value);
    } else if (target.name === 'cvc') {
      target.value = formatCVC(target.value);
    } else if (target.name === 'document') {
      target.value = formatCpfCnpj(target.value);
    } else if (target.name === "phone") {
      target.value = formatPhone(target.value);
    } else if (target.name === "postalCode") {
      target.value = formatPostalCode(target.value);
    } else if (target.name === "bornDate") {
      target.value = formatData(target.value);
    }

    this.setState({ [target.name]: target.value });
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    if (!this.state.numberCard || this.state.numberCard.replace(/\D/g, "").length < 6) {
      toast.warn('Número do cartão não informado ou incompleto.', { position: toast.POSITION.TOP_RIGHT });
      return;
    }

    if (!moment(this.state.bornDate, 'DD/MM/YYYY').isValid()) {
      toast.warn('Informe uma data de nascimento válida.', { position: toast.POSITION.TOP_RIGHT });
      return;
    }

    this.setState({
      isSaving: true
    });

    let queryParams = new URLSearchParams(this.props.location.search);
    let sessionId = queryParams.get('sessionId');

    window._PagSeguroDirectPayment.setSessionId(sessionId);

    const brand = await this.getBrand();
    if (!brand) {
      toast.warn('Não conseguimos identificar a bandeira do seu cartão. Verifique o número digitado!', { position: toast.POSITION.TOP_RIGHT });
      this.setState({ isSaving: false })
      return;
    }

    const tokenCard = await this.getToken(brand);

    this.saveUserToken(tokenCard, brand);
  }

  saveUserToken = (tokenCard, brand) => {
    const {
      nameCard,
      numberCard,
      expiry,
      cvc,
      name,
      document,
      bornDate,
      phone,
      postalCode,
      street,
      number,
      complement,
      district,
      city,
      state,
      latitude,
      longitude
    } = this.state;

    const expiration = `20${expiry.split('/')[1]}-${expiry.split('/')[0]}-01`;

    let queryParams = new URLSearchParams(this.props.location.search);
    let token = queryParams.get('token');
    let app = queryParams.get('app');

    const formData = new FormData();
    formData.set('cvv', cvc);
    formData.set('brand', brand);
    formData.set('cardName', nameCard);
    formData.set('cardNumber', numberCard.replace(/\D/g, ""));
    formData.set('expiration', expiration);
    formData.set('tokenCard', tokenCard);
    formData.set('nameDocument', name);
    formData.set('document', document.replace(/\D/g, ""));
    formData.set('typeDocument', document.length === 14 ? 1 : 2);
    formData.set('birthDate', moment(bornDate, 'DD/MM/YYYY').utc().format('YYYY-MM-DD'));
    formData.set('phone', phone.replace(/\D/g, ""));
    formData.set('postalCode', postalCode.replace(/\D/g, ""));
    formData.set('street', street);
    formData.set('number', number);
    formData.set('complement', complement);
    formData.set('district', district);
    formData.set('city', city);
    formData.set('state', state);
    formData.set('tokenPagSeguro', tokenCard);
    formData.set('latitude', latitude);
    formData.set('longitude', longitude);

    axios
      .create({
        baseURL: process.env.REACT_APP_URI_PAYMENT,
        timeout: 30000,
        headers: {
          'token': token,
          'app': app,
          'Content-Type': 'multipart/form-data'
        }
      })
      .post('/Card/SaveToken', formData)
      .then(response => {
        this.setState({ isSaving: false })
        window.location.href = `${window.location.href.split('&typeReturn')[0]}&typeReturn=success&tokenPublic=${response.data.entity}&finalNumber=${numberCard.split(' ')[3]}`;
      })
      .catch(error => {
        this.setState({ isSaving: false })
        if (error && error.response && error.response.status === 400) {
          toast.warn(error.response.data.message, { position: toast.POSITION.TOP_RIGHT });
        }
        else if (error && error.response && error.response.status === 500) {
          toast.warn('Nossos servidores não estão em bom momento. Tente novamente mais tarde.', { position: toast.POSITION.TOP_RIGHT });
        }
        else {
          toast.error('Não foi possível conectar. Verifique sua conexão e tente novamente!', { position: toast.POSITION.TOP_RIGHT });
        }
      });
  }

  getBrand = () => {
    const { numberCard } = this.state;

    return new Promise((resolve, reject) => {
      window._PagSeguroDirectPayment.getBrand({
        cardBin: numberCard.replace(/ /g, '').substring(0, 6),
        complete: (response) => {
          if (!response || !!response.error) {
            resolve('');
            return
          }
          resolve(response.brand.name);
        }
      });
    });
  }

  getToken = (brand) => {
    const {
      numberCard,
      cvc,
      expiry
    } = this.state;

    return new Promise((resolve, reject) => {
      window._PagSeguroDirectPayment.createCardToken({
        cardNumber: numberCard.replace(/ /g, ''),
        brand,
        cvv: cvc,
        expirationMonth: expiry.split('/')[0],
        expirationYear: `20${expiry.split('/')[1]}`,
        complete: (response) => {
          if (!response || !!response.error) {
            resolve('');
            return
          }

          resolve(response.card.token)
        }
      });
    });
  }

  render() {
    const {
      nameCard,
      numberCard,
      expiry,
      cvc,
      focused,
      name,
      document,
      bornDate,
      phone,
      postalCode,
      street,
      number,
      complement,
      district,
      city,
      state,
      isSaving
    } = this.state;

    return (
      <div className="App">
        {isSaving
          ? <div className="container-full"><Spinner /></div>
          : <Col sm={12} md={8} xl={4}>

            <div style={{ marginTop: 10 }}>
              <FormGroup>
                <Cards
                  number={numberCard}
                  name={nameCard}
                  expiry={expiry}
                  cvc={cvc}
                  focused={focused}
                  callback={this.handleCallback}
                  placeholders={{ name: 'Nome no cartão' }}
                />

                <FormGroup style={{ marginTop: 20 }}>
                  <Input type="tel"
                    name="numberCard"
                    className="form-control"
                    placeholder="Número do cartão"
                    pattern="[\d| ]{16,22}"
                    required
                    value={numberCard}
                    tabIndex={1}
                    onChange={this.handleInputChange}
                    onFocus={this.handleInputFocus} />
                </FormGroup>
                <FormGroup>
                  <Input
                    type="text"
                    name="nameCard"
                    className="form-control"
                    placeholder="Nome no cartão"
                    required
                    tabIndex={2}
                    value={nameCard}
                    onChange={this.handleInputChange}
                    onFocus={this.handleInputFocus}
                  />
                </FormGroup>
                <Row style={{ marginBottom: 15 }}>
                  <div className="col-6">
                    <Input
                      name="expiry"
                      className="form-control"
                      placeholder="Vencimento"
                      pattern="\d\d/\d\d"
                      required
                      value={expiry}
                      tabIndex={3}
                      onChange={this.handleInputChange}
                      onFocus={this.handleInputFocus} />
                  </div>
                  <div className="col-6">
                    <Input
                      name="cvc"
                      className="form-control"
                      placeholder="CVV"
                      pattern="\d{3,4}"
                      required
                      tabIndex={4}
                      value={cvc}
                      onChange={this.handleInputChange}
                      onFocus={this.handleInputFocus}
                       />
                  </div>
                </Row>
              </FormGroup>

              <FormGroup>
                <Label>Titular do cartão</Label>
                <div>
                  <Input
                    type="text"
                    name="name"
                    className="form-control"
                    placeholder="Nome completo ou razão social"
                    required
                    value={name}
                    tabIndex={5}
                    onChange={this.handleInputChange}
                    onFocus={this.handleInputFocus}
                  />
                </div>

                <Row>
                  <Col sm={12} md={6} xl={6} style={{ marginTop: 15 }}>
                    <Input
                      name="document"
                      className="form-control"
                      placeholder="CPF/CNPJ"
                      type="text"
                      required
                      maxLength={18}
                      tabIndex={6}
                      value={document}
                      onChange={this.handleInputChange} />
                  </Col>
                  <Col sm={12} md={6} xl={6} style={{ marginTop: 15 }}>
                    <Input
                      name="bornDate"
                      className="form-control"
                      placeholder="Data de nascimento"
                      type="tel"
                      pattern="\d\d/\d\d/\d\d\d\d"
                      required
                      tabIndex={7}
                      value={bornDate}
                      onChange={this.handleInputChange} />
                  </Col>
                </Row>

                <Row>
                  <Col sm={12} md={6} xl={6} style={{ marginTop: 15 }}>
                    <Input
                      type="tel"
                      name="phone"
                      className="form-control"
                      placeholder="Telefone"
                      maxLength={15}
                      tabIndex={8}
                      required
                      value={phone}
                      onChange={this.handleInputChange} />
                  </Col>
                </Row>
              </FormGroup>

              {/* <FormGroup> 
                <Label style={{ marginBottom: -10 }}>Endereço de faturamento</Label>
                <Row>
                  <Col sm={12} md={6} xl={6} style={{ marginTop: 15 }}>
                    <Input
                      type="text"
                      name="postalCode"
                      className="form-control"
                      placeholder="CEP"
                      pattern="\d{5}-?\d{3}"
                      required
                      tabIndex={9}
                      maxLength={9}
                      value={postalCode}
                      onChange={this.handleInputChange} />
                  </Col>
                  <Col sm={12} md={6} xl={6} style={{ marginTop: 15 }}>
                    <Input
                      type="text"
                      name="street"
                      className="form-control"
                      placeholder="Endereço"
                      required
                      tabIndex={10}
                      value={street}
                      onChange={this.handleInputChange} />
                  </Col>
                </Row>

                <Row>
                  <Col sm={12} md={6} xl={6} style={{ marginTop: 15 }}>
                    <Input
                      type="text"
                      name="number"
                      className="form-control"
                      placeholder="Número"
                      required
                      value={number}
                      tabIndex={11}
                      onChange={this.handleInputChange} />
                  </Col>

                  <Col sm={12} md={6} xl={6} style={{ marginTop: 15 }}>
                    <Input
                      type="text"
                      name="complement"
                      className="form-control"
                      placeholder="Complemento"
                      value={complement}
                      tabIndex={12}
                      onChange={this.handleInputChange} />
                  </Col>
                </Row>

                <Row>
                  <Col sm={12} md={6} xl={6} style={{ marginTop: 15 }}>
                    <Input
                      type="text"
                      name="district"
                      className="form-control"
                      placeholder="Bairro"
                      required
                      value={district}
                      tabIndex={13}
                      onChange={this.handleInputChange} />
                  </Col>

                  <Col sm={12} md={6} xl={6} style={{ marginTop: 15 }}>
                    <Input
                      type="text"
                      name="city"
                      className="form-control"
                      placeholder="Cidade"
                      required
                      value={city}
                      tabIndex={14}
                      onChange={this.handleInputChange} />
                  </Col>
                </Row>

                <Row>
                  <Col sm={12} md={6} xl={6} style={{ marginTop: 15 }}>
                    <Input
                      type="text"
                      name="state"
                      className="form-control"
                      placeholder="UF"
                      maxLength={2}
                      tabIndex={15}
                      required
                      value={state}
                      onChange={this.handleInputChange} />
                  </Col>
                </Row>
              </FormGroup>*/}

              <div className="form-actions" style={{ marginTop: 30, marginBottom: 30 }}>
                <button className="btn btn-success btn-block btn-rounded" type="submit" onClick={this.handleSubmit}>
                  Utilizar este cartão
            </button>
              </div>
            </div>
          </Col>}
      </div>
    )
  }
}
