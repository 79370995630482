import React, { Component } from 'react'
import Spinner from './Spinner';
import { toast } from 'react-toastify';
import Axios from 'axios';
export default class Pay extends Component {

  componentDidMount() {
    let queryParams = new URLSearchParams(this.props.location.search);
    let token = queryParams.get('token');
    let app = queryParams.get('app');
    let sessionId = queryParams.get('sessionId');

    if (!token || !app) {
      toast.error('Não foi possível autorizar!', { position: toast.POSITION.TOP_RIGHT });
      window.location.href = `${window.location.href.split('&typeReturn')[0]}&typeReturn=error&message=${encodeURIComponent('Não foi possível autorizar!')}`;
      return;
    }

    if (!sessionId) {
      toast.error('Sessão não informada!', { position: toast.POSITION.TOP_RIGHT });
      window.location.href = `${window.location.href.split('&typeReturn')[0]}&typeReturn=error&message=${encodeURIComponent('Sessão não informada!')}`;
      return;
    }

    window._PagSeguroDirectPayment.setSessionId(sessionId);
  }

  componentWillMount() {
    const queryParams = new URLSearchParams(this.props.location.search);
    if (!!queryParams.get('message'))
      return;

    setTimeout(() => {
      

      const app = queryParams.get('app');
      const token = queryParams.get('token');

      const hash = window._PagSeguroDirectPayment.getSenderHash();      
  
      const pay = {
        accountDestinyToken: queryParams.get('accountDestinyToken'),
        payerToken: queryParams.get('payerToken'),
        description: decodeURIComponent(queryParams.get('description')),
        typePayment:  parseInt(queryParams.get('typePayment')),
        value: parseFloat(queryParams.get('value')),
        valuePayment: parseFloat(queryParams.get('valuePayment')),
        valueBonus: parseFloat(queryParams.get('valueBonus') || 0),
        latitude: parseFloat(queryParams.get('latitude')),
        longitude: parseFloat(queryParams.get('longitude')),
        typeTransaction: parseInt(queryParams.get('typeTransaction')),
        hash,
        installments: queryParams.get('installments'),
        reference: decodeURIComponent(queryParams.get('reference')),
        sessionId: decodeURIComponent(queryParams.get('sessionId'))
      }

      Axios
      .create({
        baseURL: process.env.REACT_APP_URI_PAYMENT,
        timeout: 30000,
        headers: {
          'token': token,
          'app': app,
          'Content-Type': 'application/json'
        }
      })
      .post('/Payment/Transaction', pay)
      .then(response => {
        this.setState({ isSaving: false })
        window.location.href = `${window.location.href.split('&typeReturn')[0]}&typeReturn=success&message=${response.data.message}&url=${response.data.url ? encodeURIComponent(response.data.url) : ''}`;
      })
      .catch(error => {
        this.setState({ isSaving: false })
        if (error && error.response && error.response.status === 400) {
          window.location.href = `${window.location.href.split('&typeReturn')[0]}&typeReturn=warn&message=${encodeURIComponent(error.response.data.message)}`;
        }
        else if (error && error.response && error.response.status === 500) {
          window.location.href = `${window.location.href.split('&typeReturn')[0]}&typeReturn=warn&message=${encodeURIComponent('Nossos servidores não estão em bom momento. Tente novamente mais tarde.')}`;
        }
        else {
          window.location.href = `${window.location.href.split('&typeReturn')[0]}&typeReturn=error&message=${encodeURIComponent('Não foi possível conectar. Verifique sua conexão e tente novamente!')}`;
        }
      });

    }, 2000);
  }

  render() {

    return (
      <div className="App">
        <div className="container-full"><Spinner /></div>
      </div>
    )
  }
}
