import React, { Component } from 'react';
import { Alert, Col, ListGroup, ListGroupItem, Row, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { visa, mastercard, amex, diners, hipercard, elo, discover, defaultCard } from './IconsCard';
import { carregarCartoes, removeCartao } from '../actions';
import { connect } from 'react-redux';
import Spinner from './Spinner';
import Axios from 'axios';
import { toast } from 'react-toastify';
import Icon from 'react-eva-icons';

const TYPE_TRANSACTION = {
  CreditCard: 1,
  Boleto: 3
}

class PayOnline extends Component {

  state = {
    description: '',
    ordem: '',
    value: 0,
    valuePayment: 0,
    valueBonus: 0,
    cards: [],
    cardSelected: undefined,
    payerBoleto: '',
    typeTransaction: TYPE_TRANSACTION.CreditCard,
    isSaving: false,
    isLoadingOrdem: false,
    sucesso: false
  }

  componentDidMount() {
    const queryParams = new URLSearchParams(this.props.location.search);

    const token = queryParams.get('token');
    const app = queryParams.get('app');
    const sessionId = queryParams.get('sessionId');
    const ordem = queryParams.get('ordem');

    if (!token || !app || !sessionId || !ordem) {
      this.setState({ message: 'Não foi possível continuar!' });
      return;
    }

    sessionStorage.setItem('token', token);
    sessionStorage.setItem('app', app);
    sessionStorage.setItem('sessionId', sessionId);

    this.consultarOrdem(app, token, ordem);
  }

  consultarOrdem = (app, token, ordem) => {
    this.setState({
      isLoadingOrdem: true,
      message: ''
    }, () => {

      Axios
        .create({
          baseURL: process.env.REACT_APP_URI_APP,
          timeout: 30000,
          headers: {
            'token': token,
            'app': app,
            'Content-Type': 'application/json'
          }
        })
        .get(`/Pagamento/ConsultarOrdem?ordem=${ordem}`)
        .then(response => {
          this.setState({
            isLoadingOrdem: false,
            ordem,
            description: response.data.entity.descricaoConta,
            value: response.data.entity.valor,
            valueBonus: response.data.entity.valorBonus,
            valuePayment: response.data.entity.valorPagamento,
          }, this.props.consultarMeusCartoes());
        })
        .catch(error => {
          if (error.response && error.response.data && error.response.data.message) {
            this.setState({
              message: error.response.data.message,
              isLoadingOrdem: false
            });
          }
          else {
            this.setState({
              message: 'Ocorreu um erro!',
              isLoadingOrdem: false
            });
          }
        });
    })
  }


  componentWillReceiveProps(nextProps) {
    if (!this.state.cardSelected && !!nextProps.cards && nextProps.cards.length) {
      this.setState({
        cardSelected: nextProps.cards[0]
      })
    }
  }

  getBandeira = (row) => {
    switch (row.brand.toLowerCase()) {
      case 'visa': return visa;
      case 'mastercard': return mastercard;
      case 'amex': return amex;
      case 'diners': return diners;
      case 'hipercard': return hipercard;
      case 'elo': return elo;
      case 'discover': return discover;
      default: return defaultCard;
    }
  }

  changeCardList = (card) => {
    this.setState({
      cardSelected: this.state.cardSelected && this.state.cardSelected.tokenPublic === card.tokenPublic
        ? undefined
        : card
    })
  }

  pay = () => {
    this.setState({
      isLoadingOrdem: true
    }, async () => {

      const token = sessionStorage.getItem('token');
      const app = sessionStorage.getItem('app');
      const sessionId = sessionStorage.getItem('sessionId');

      const {
        ordem,
        typeTransaction
      } = this.state;

      let payerToken = this.state.cardSelected ? this.state.cardSelected.tokenPublic : '';

      const hash = window._PagSeguroDirectPayment.getSenderHash();
      const pay = {
        payerToken: payerToken,
        tipoTransacao: typeTransaction,
        ordem,
        hash,
        sessionId
      }

      Axios
        .create({
          baseURL: process.env.REACT_APP_URI_APP,
          timeout: 30000,
          headers: {
            'token': token,
            'app': app,
            'Content-Type': 'application/json'
          }
        })
        .post('/Pagamento/ExecutarOrdem', pay)
        .then(response => {
          this.setState({ isLoadingOrdem: false, sucesso: typeTransaction === TYPE_TRANSACTION.CreditCard ? true : false }, () => {
            if (typeTransaction === TYPE_TRANSACTION.Boleto)
              window.location.href = response.data.url
          })

        })
        .catch(error => {
          this.setState({ isLoadingOrdem: false })
          if (error && error.response && error.response.status === 400) {
            toast.warn(error.response.data.message, { position: toast.POSITION.TOP_RIGHT });
          }
          else if (error && error.response && error.response.status === 500) {
            toast.warn('Nossos servidores não estão em bom momento. Tente novamente mais tarde.', { position: toast.POSITION.TOP_RIGHT });
          }
          else {
            toast.error('Não foi possível conectar. Verifique sua conexão e tente novamente!', { position: toast.POSITION.TOP_RIGHT });
          }
        });
    });
  }

  changeType = (type) => {
    this.setState({ typeTransaction: type })
  }

  delete = (row) => {
    this.props.removeCartao(row.tokenPublic);
  }

  render() {
    const {
      description,
      value,
      valueBonus,
      valuePayment,
      cardSelected,
      ordem,
      typeTransaction,
      isSaving,
      message,
      isLoadingOrdem,
      sucesso
    } = this.state;

    const {
      isLoading,
      cards
    } = this.props;

    if (isLoadingOrdem) {
      return (
        <div className="App">
          <div className="container-full"><Spinner /></div>
        </div>
      )
    }

    if (isSaving) {
      return (
        <div className="App">
          <div className="container-full"><Spinner /></div>
        </div>
      )
    }

    if (message) {
      return (
        <div className="container-full">
          <div className="container-loading">
            <h4>{message}</h4>
            <h6>Acesse o aplicativo e realize uma nova doação!</h6>
          </div>
        </div>
      )
    }

    if (sucesso) {
      return (
        <div className="container-full">
          <div className="container-loading">
            <Alert color="primary" style={{ marginLeft: '10px', marginRight: '10px' }}>
              {`Doação no valor ${new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL', minimumFractionDigits: 2 }).format(value)} enviada. Verifique o histórico para ver ser foi confirmada!`}
            </Alert>
          </div>
        </div>

      )
    }

    return (
      <div className="App">
        <Col sm={12} md={8} xl={4} className="content-info">
          <h4 style={{ marginTop: '10px' }}>Doação Yve</h4>

          <div>
            <Alert color="success" className="info-pagamento">
              <strong>{description}</strong>
              <br />
              <br />
              <strong> {new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL', minimumFractionDigits: 2 }).format(value)}</strong>
              <br />
              Método:
              <br />
              {!!valuePayment && !!valueBonus && <span> {new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL', minimumFractionDigits: 2 }).format(valuePayment)} no {typeTransaction === TYPE_TRANSACTION.CreditCard ? 'cartão' : 'boleto'} <br /> {new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL', minimumFractionDigits: 2 }).format(valueBonus)} de saldo</span>}
              {!!valuePayment && !valueBonus && <span> {new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL', minimumFractionDigits: 2 }).format(valuePayment)} no {typeTransaction === TYPE_TRANSACTION.CreditCard ? 'cartão' : 'boleto'}</span>}
              {!valuePayment && !!valueBonus && <span> {new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL', minimumFractionDigits: 2 }).format(valueBonus)} de saldo</span>}
            </Alert>

            <div className="content-donation-center">
              <UncontrolledDropdown>
                <DropdownToggle caret color="primary" className="full-width bottom-margin">
                  {typeTransaction === TYPE_TRANSACTION.CreditCard ? 'Cartão de crédito' : 'Boleto'}
                </DropdownToggle>
                <DropdownMenu className="full-width">
                  <DropdownItem onClick={() => this.changeType(TYPE_TRANSACTION.CreditCard)}>Cartão de crédito</DropdownItem>
                  <DropdownItem divider />
                  <DropdownItem onClick={() => this.changeType(TYPE_TRANSACTION.Boleto)}>Boleto</DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>

              {typeTransaction === TYPE_TRANSACTION.CreditCard &&
                <div>
                  {isLoading
                    ? <Spinner />
                    : <ListGroup>
                      {cards.map((row, i) => {
                        const rowSelecionada = !!cardSelected && cardSelected.tokenPublic === row.tokenPublic;
                        return (
                          <ListGroupItem key={i}>
                            <Row onClick={() => this.changeCardList(row)}>
                              <input
                                className="inp-cbx"
                                type="checkbox"
                                style={{ display: 'none' }}
                                checked={rowSelecionada}
                                value={rowSelecionada}
                                readOnly
                              />
                              <label className="cbx label-content"><span>
                                <svg width="12px" height="10px" viewBox="0 0 12 10">
                                  <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
                                </svg></span>
                                <div className="content-list-card">
                                  <div className="image-card" dangerouslySetInnerHTML={{ __html: this.getBandeira(row) }} />
                                  <span>**** **** **** {row.finalNumber}</span>

                                  <div className="content-border" onClick={() => this.delete(row)}>
                                    <Icon
                                      name="trash-2"
                                      size="medium"
                                      fill="#FF4032"
                                      animation={{
                                        type: "pulse",
                                        hover: true,
                                        infinite: false
                                      }}
                                    />
                                  </div>
                                </div>
                              </label>
                            </Row>
                          </ListGroupItem>
                        )
                      })
                      }
                    </ListGroup>}

                  {cardSelected &&
                    <div className="form-actions" style={{ marginTop: 20 }}>
                      <button className="btn btn-success btn-block btn-rounded" onClick={() => this.pay()}>
                        Doar com o cartão {cardSelected.finalNumber}
                      </button>
                    </div>
                  }

                  <div className="form-actions" style={{ marginTop: 30, marginBottom: 30 }}>
                    <button className="btn btn-primary btn-block btn-rounded" onClick={() => this.props.history.push(`/cardonline?ordem=${ordem}`)}>
                      Adicionar um novo cartão
                    </button>
                  </div>
                </div>}

              {typeTransaction === TYPE_TRANSACTION.Boleto &&
                <div className="form-actions" style={{ marginTop: 20 }}>
                  <button className="btn btn-success btn-block btn-rounded" onClick={() => this.pay()}>
                    Gerar boleto
                  </button>
                </div>}
            </div>
          </div>
        </Col>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    cards: state.creditCards.cards,
    isLoading: state.creditCards.isLoading
  }
}

const mapDispatchToProps = dispatch => {
  return {
    consultarMeusCartoes: () => {
      dispatch(carregarCartoes());
    },
    removeCartao: (tokenPublic) => {
      dispatch(removeCartao(tokenPublic));
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PayOnline);
