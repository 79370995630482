import {
  SET_LISTA_CARTOES,
  SET_CONSULTANDO_CARTOES
} from '../actions/actionsTypes';
import Axios from 'axios';

export const carregarCartoes = params => async (dispatch) => {

  dispatch({
    type: SET_CONSULTANDO_CARTOES
  });

  const token = sessionStorage.getItem('token');
  const app = sessionStorage.getItem('app');

  Axios
    .create({
      baseURL: process.env.REACT_APP_URI_PAYMENT,
      timeout: 30000,
      headers: {
        'token': token,
        'app': app,
        'Content-Type': 'application/json'
      }
    })
    .get('/Card/MyCards')
    .then(response => {
      dispatch({
        type: SET_LISTA_CARTOES,
        payload: { isLoading: false, cards: response.data && response.data.length ? response.data : [] }
      });

    })
    .catch(e => {
      dispatch({
        type: SET_LISTA_CARTOES,
        payload: { isLoading: false, cards: [] }
      });
    });
};

export const removeCartao = params => async (dispatch, getState) => {
  if (!params)
    return;

  let { cards } = getState().creditCards;

  dispatch({
    type: SET_LISTA_CARTOES,
    payload: { isLoading: false, cards: cards.filter(x => x.tokenPublic != params) }
  });

  const token = sessionStorage.getItem('token');
  const app = sessionStorage.getItem('app');

  Axios
    .create({
      baseURL: process.env.REACT_APP_URI_PAYMENT,
      timeout: 30000,
      headers: {
        'token': token,
        'app': app,
        'Content-Type': 'application/json'
      }
    })
    .delete(`/Card/Remove?publicKey=${params}`)
    .then(response => {

    })
    .catch(e => {

    });

}